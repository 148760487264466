/* eslint-disable react/destructuring-assignment */

import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import {
  AddCircleOutlineRounded,
  ArrowForwardOutlined,
  CloseOutlined,
  DeleteOutlineOutlined,
  PersonAddAltOutlined,
  PersonAddOutlined,
} from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';

import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { changeCoManagersUseCase } from 'useCases/partnersManagement/changeCoManagersUseCase';
import { searchUserBySectorUseCase } from 'useCases/partnersManagement/searchUserBySectorUseCase';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { info1, info2 } from '../PartnersManagementPage.styles';

function DrawerPartnerCoManagers() {
  const {
    isOpenDrawerPartnerCoManagers,
    isLoadingCoManagers,
    selectedRow,
    isOpenDrawerPartnerCoManagersModal,
    coManagerUserInfo,
  } = useStore(partnersManagementStore);

  const [listCoManagers, setListCoManagers] = useState(() => [
    { id: Math.random() * 999, sector: '', name: '', role: '' },
  ]);

  const onClose = () => {
    partnersManagementEvent({ isOpenDrawerPartnerCoManagers: false, isOpenDrawerDetails: true });
  };

  const handleButtonDisabled = () => {
    return listCoManagers.every(obj => obj.name.trim() === '' || obj.sector.trim() === '');
  };

  useEffect(() => {
    const filtered = selectedRow?.users?.filter(obj => !obj?.owner);
    if (filtered?.length > 0) {
      setListCoManagers(
        filtered.map(obj => ({
          id: Math.random() * 999,
          sector: obj.sector,
          name: obj.name,
          role: obj.role || '',
        })),
      );
    }
  }, [isOpenDrawerPartnerCoManagers, selectedRow]);

  useEffect(() => {
    if (coManagerUserInfo) {
      const result = coManagerUserInfo[0];
      if (!result) return;
      setListCoManagers(oldState =>
        oldState.map(obj =>
          obj.sector === result?.setor
            ? { ...obj, name: result?.nome, role: coManagerUserInfo?.role }
            : obj,
        ),
      );
    }
  }, [coManagerUserInfo]);

  useEffect(() => {
    partnersManagementEvent({ coManagerUserInfo: null });
    setListCoManagers([{ id: Math.random() * 999, sector: '', name: '', role: '' }]);
  }, []);

  return (
    <>
      <Drawer anchor="right" open={isOpenDrawerPartnerCoManagers} onClose={onClose}>
        <Stack
          p={4}
          direction="column"
          flex={1}
          sx={{
            width: '640px',
            backgroundColor: 'white',
            paddingBottom: 5,
            borderRadius: '15px 0 0 15px',
          }}
        >
          <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack text="VOLTAR" onBack={onClose} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={6}>
            Alterar cogestão
          </Typography>

          <Typography fontSize={14} fontWeight={700}>
            COGESTÃO ATUAL
          </Typography>
          <Divider />
          <Stack direction="row" gap={2} mt={2}>
            <Box>
              <PersonAddAltOutlined sx={{ fontSize: '46px' }} />
            </Box>
            <Box>
              <Typography sx={info2}>COGESTOR</Typography>
              <Stack>
                {selectedRow?.users
                  ?.filter(obj => !obj?.owner)
                  ?.map(obj => (
                    <Typography sx={info1}>{obj?.name}</Typography>
                  ))}
              </Stack>
            </Box>
          </Stack>

          <Typography fontSize={14} fontWeight={700} mt={6}>
            ALTERAR COGESTÃO
          </Typography>
          <Divider />
          {listCoManagers?.map((obj, index) => (
            <Box mt={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Stack direction="row" alignItems="center" gap={2} style={{ width: '80%' }}>
                  <Stack>
                    {index === 0 && <Typography fontSize="14px">Código</Typography>}
                    <TextInput
                      startAdornment={
                        <>
                          <PersonAddOutlined />
                          <div style={{ width: 8 }} />
                        </>
                      }
                      maxLength={8}
                      placeholder="00000000"
                      value={listCoManagers[index].sector}
                      onChange={e => {
                        const val = e.target.value;
                        setListCoManagers(oldState =>
                          oldState.map(item =>
                            item.id === obj.id ? { ...item, sector: val } : item,
                          ),
                        );
                        if (val?.length >= 8) {
                          searchUserBySectorUseCase.execute(val);
                        }
                      }}
                      onBlur={e => searchUserBySectorUseCase.execute(e.target.value)}
                    />
                  </Stack>
                  <Stack>
                    {index === 0 && <Typography fontSize="14px">Nome</Typography>}
                    <TextInput disabled placeholder="Nome" value={listCoManagers[index].name} />
                  </Stack>
                </Stack>
                {index === listCoManagers.length - 1 && (
                  <IconButton
                    color="success"
                    onClick={() =>
                      setListCoManagers(oldState => [
                        ...oldState,
                        { id: Math.random() * 999, sector: '', name: '', role: '' },
                      ])
                    }
                  >
                    <AddCircleOutlineRounded />
                  </IconButton>
                )}
                {listCoManagers.length > 1 && (
                  <IconButton
                    color="error"
                    onClick={() =>
                      setListCoManagers(oldState => oldState.filter(item => item.id !== obj.id))
                    }
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                )}
              </Stack>
            </Box>
          ))}

          <Button
            variant="contained"
            sx={{ width: 250, mt: 8 }}
            disabled={handleButtonDisabled()}
            endIcon={<ArrowForwardOutlined color="inherit" />}
            onClick={() => partnersManagementEvent({ isOpenDrawerPartnerCoManagersModal: true })}
          >
            ALTERAR COGESTÃO
          </Button>
        </Stack>
      </Drawer>
      <ConfirmModal
        open={isOpenDrawerPartnerCoManagersModal}
        setOpen={v => partnersManagementEvent({ isOpenDrawerPartnerCoManagersModal: v })}
        title="Atenção"
        text="Tem certeza que deseja alterar a cogestão dessa parceria?"
        onConfirm={() =>
          changeCoManagersUseCase.execute({
            listCoManagers,
            reset: () =>
              setListCoManagers([{ id: Math.random() * 999, sector: '', name: '', role: '' }]),
          })
        }
        isLoading={isLoadingCoManagers}
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DrawerPartnerCoManagers;
