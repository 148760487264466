import { RequestService } from 'services/RequestService/RequestService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';

import { authStore } from 'stores/AuthStore/AuthStore';
import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';
import { getPartnersManagementUseCase } from './getPartnersManagementUseCase';

const execute = async (form: any) => {
  try {
    const { sector } = authStore.getState();

    await RequestService.editValidityRequestManagementDetail(
      form.formId,
      sector,
      form.startDate,
      form.endDate,
    );
    alertEvent({
      open: true,
      text: 'Vigência alterada com sucesso.',
      type: 'success',
    });
  } catch (err) {
    // console.log(err)
  } finally {
    partnersManagementEvent({ isOpenEditValidityModal: false });
    getPartnersDetailsUseCase.execute();
    getPartnersManagementUseCase.execute();
  }
};

export const editValidityModalPartnersManagementUseCase = { execute };
